import revive_payload_client_1IIleuOwbI from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CgofB3xaKT from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SoRefSj3N6 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_soznVQPlyI from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NjI5CjoRCb from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_s0HhoLf1vh from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_c0YZUMfH4S from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_2ATCsOtlTL from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@3.29.4_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/addicted.nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_gybPvQCcaZ from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_bufferutil@4.0.8_eslint@9.9.1_rollup@3.29.4_typescript@5.5.4__72mupzqypfohvwamqiy33itwwm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CmOmRGIIVT from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@nuxtjs+device@3.2.2_@types+node@20.16.2_bufferutil@4.0.8_h3@1.12.0_nitropack@2.9.7_nuxi@3.13_4h3i6bbpcxmxha4x2p6kejh6zu/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_aCMCYz2xgw from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_pinia@2.2.2_rollup@3.29.4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/opt/buildhome/repo/addicted.nuxt/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/opt/buildhome/repo/addicted.nuxt/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/addicted.nuxt/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/opt/buildhome/repo/addicted.nuxt/plugins/vuetify.ts";
export default [
  revive_payload_client_1IIleuOwbI,
  unhead_CgofB3xaKT,
  router_SoRefSj3N6,
  payload_client_soznVQPlyI,
  navigation_repaint_client_NjI5CjoRCb,
  check_outdated_build_client_s0HhoLf1vh,
  chunk_reload_client_c0YZUMfH4S,
  plugin_vue3_2ATCsOtlTL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gybPvQCcaZ,
  plugin_CmOmRGIIVT,
  plugin_aCMCYz2xgw,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH
]